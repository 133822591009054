import React, { useEffect } from "react";
import { useState } from "react";
import Header from "./Header";
import Loading from "./Loading";
import Modal from "./Modal";
import "../css/Wallet.css";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import BASE_URL from "./Constants";
import { Grid, Select, Button, Input } from "@mui/material";
import "react-multi-carousel/lib/styles.css";
import ReactQuill from "react-quill";
import { MenuItem } from "@mui/material";
import {
	Add,
	ArrowLeft,
	ArrowRight,
	ArrowDropDown,
	Delete,
} from "@mui/icons-material";
import api from "../api/index";
import CustomAlert from "./Alert";
console.log(api);
const parseMonth = (month) => {
	switch (month) {
		case 1:
			return "Gennaio";
		case 2:
			return "Febbraio";
		case 3:
			return "Marzo";
		case 4:
			return "Aprile";
		case 5:
			return "Maggio";
		case 6:
			return "Giugno";
		case 7:
			return "Luglio";
		case 8:
			return "Agosto";
		case 9:
			return "Settembre";
		case 10:
			return "Ottobre";
		case 11:
			return "Novembre";
		case 12:
			return "Dicembre";
		default:
			return "Gennaio";
	}
};

const Read = () => {
	const isLoggedIn = localStorage.getItem("isLoggedIn");
	const [pages, setPages] = useState([]);
	const { id } = useParams();
	const [currentPage, setCurrentPage] = useState(0);
	const isLoadingGlobal = useSelector((state) => state.loadingState.value);
	const navigate = useNavigate();
	const codUser = localStorage.getItem("id");
	const [chapterList, setChapterList] = useState([]);
	const [chapter, setChapter] = useState(chapterList?.[0]?.id);
	const [comments, setComments] = useState([]);
	const [textPage, setTextPage] = useState([]);
	const [isLoadingLocal, setIsLoadingLocal] = useState(isLoadingGlobal);
	const [commentText, setCommentText] = useState("");

	function doDonation() {
		api
			.post(`${BASE_URL}/donation/create`, {
				cod_receiver: chapterList?.[0]?.cod_user,
				amount: donation,
				cod_book: id,
			})
			.then((res) => {
				CustomAlert({
					type: "success",
					title: "Donazione effettuata",
					text: "La tua donazione è stata effettuata con successo",
					cancelButton: "Chiudi",
				});
			})
			.catch((err) => {
				console.log();
				CustomAlert({
					type: "error",
					title: "Donazione non effettuata",
					body: err.response.data?.message,
					cancelButton: "Chiudi",
				});
			});
	}

	function deleteComment(id) {
		api.delete(`${BASE_URL}/comment/delete/${id}`).then((res) => {
			setComments(res.data?.data);
		});
	}

	const CommentElement = ({ comment }) => {
		const date = new Date(comment?.timestamp);
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();
		const hour = date.getHours();
		const minutes = date.getMinutes();
		const stringDate = `${("0" + day).slice(-2)} ${parseMonth(
			month
		)} ${year} ${hour}:${minutes}`;
		return (
			<div
				style={{
					width: "100%",
					backgroundColor: "#f4e9eb",
					borderRadius: 20,
					marginTop: 15,
					maxHeight: "175px",
				}}
			>
				<div
					style={{
						display: "flex",
						paddingRight: 10,
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						fontSize: 12,
						marginBottom: 5,
						paddingLeft: 10,
					}}
				>
					<span style={{ marginTop: 10 }}>{stringDate}</span>

					{codUser.toString() === comment?.cod_user?.toString() && (
						<Delete
							style={{
								cursor: "pointer",
								color: "#f29cae",
								marginRight: 10,
								marginTop: 10,
							}}
							onClick={() => deleteComment(comment?.id)}
						/>
					)}
				</div>
				<div
					style={{
						padding: 10,
						display: "flex",
						flexDirection: "row",
						borderBottom: "2px solid #f29ca4",
						marginLeft: 10,
						marginRight: 10,
					}}
				>
					<span
						style={{
							width: "48px",
							height: "48px",
							margin: "0 0 0 0",
							padding: "10px 10px 10px 10px",
							border: "solid 1px #d99d9d",
							borderRadius: "50%",
							alignContent: "center",
							justifyContent: "center",
							display: "flex",
							backgroundImage: `url(${comment?.imgUser})`,
							objectFit: "cover",
							backgroundSize: "cover",
							backgroundRepeat: "no-repeat",
							backgroundPosition: "center",
						}}
					>
						{comment?.letter}
					</span>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "flex-start",
							marginLeft: 10,
							overflowX: "scroll",
							overflowY: "hidden",
						}}
					>
						<h6 style={{ margin: 0 }}>
							{comment?.name} {comment?.surname}{" "}
						</h6>
					</div>
				</div>
				<div
					style={{
						padding: 10,
						height: "auto",
						overflowY: "scroll",
						marginTop: 10,
						marginBottom: 10,
						maxHeight: "80px",
					}}
				>
					<span>{comment?.text}</span>
				</div>
			</div>
		);
	};

	function createComment() {
		api
			.post(`${BASE_URL}/comment/create`, {
				text: commentText,
				cod_page: textPage[currentPage]?.id,
			})
			.then((res) => {
				setComments(res.data?.data);
				setCommentText("");
			});
	}
	useEffect(() => {
		api.get(`${BASE_URL}/chapter/getByBookId/${id}`).then((res) => {
			setChapterList(res.data?.data);
			setChapter(res.data?.data[0]?.id.toString());
		});
	}, []);

	useEffect(() => {
		console.log(chapter);
	}, [chapter]);

	useEffect(() => {
		if (!chapter) return;
		api.get(`${BASE_URL}/pages/getAllChapter/${chapter}`).then((res) => {
			setPages(res.data?.data);
			setTextPage(res.data?.data);
			setCurrentPage(0);
		});
	}, [chapter]);

	useEffect(() => {
		if (!textPage[currentPage]?.id) return;
		api
			.get(`${BASE_URL}/comment/getByPage/${textPage[currentPage]?.id}`)
			.then((res) => {
				setComments(res.data?.data);
			});
	}, [currentPage, textPage]);

	setTimeout(() => {
		setIsLoadingLocal(false);
	}, 1900);

	const [donation, setDonation] = useState();

	let wallet = (
		<div>
			<Header
				title='LEGGI'
				to='/home'
			/>
			<Grid
				container
				style={{
					padding: 30,
					backgroundImage: "url(./backgroud-read.png)",
					backgroundAttachment: "scroll",
					backgroundSize: "cover",
				}}
			>
				<Grid
					item
					xs={3}
					style={{
						height: "75vh",
						borderRadius: 50,
						padding: 30,
						border: "3px solid #f29ca4",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<div
						style={{
							height: "72vh",
							overflowY: "scroll",
							overscrollBehaviorY: "contain",
						}}
					>
						<div
							style={{
								width: "100%",
								height: "60px",
								borderRadius: 50,
								backgroundColor: "#f29ca4",
								display: "flex",
								justifyContent: "center",
								alignContent: "center",
								alignItems: "center",
								color: "#fff",
								fontSize: 25,
								fontWeight: "bold",
							}}
						>
							Commenti
						</div>
						<div style={{ borderBottom: "3px solid #f29ca4" }}>
							<Input
								style={{
									marginTop: 30,
									width: "100%",
									maxHeight: 60,
									backgroundColor: "#eee",
									opacity: 0.8,
									borderRadius: 10,
									padding: 10,
									height: 60,
									overflowX: "scroll",
								}}
								value={commentText}
								onChange={(val) => setCommentText(val.target.value)}
								multiline
								placeholder='Aggiungi un commento'
							/>
							<div
								style={{
									width: "100%",
									display: "flex",
									justifyContent: "flex-end",
								}}
							>
								<Button
									text='Invia'
									style={{
										marginTop: 10,
										display: "flex",
										fontSize: 10,
										backgroundColor: "#f29ca4",
										borderRadius: 20,
										marginBottom: 10,
										color: "#fff",
										fontWeight: "bold",
									}}
									onClick={() => createComment()}
									startIcon={<Add />}
								>
									Aggiungi
								</Button>
							</div>
						</div>

						{comments.map((el) => (
							<CommentElement comment={el} />
						))}
					</div>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
						}}
					>
						<ArrowDropDown
							style={{
								width: 50,
								height: 50,
								marginTop: -10,
								color: "#f29ca4",
								cursor: "pointer",
							}}
						/>
					</div>
				</Grid>
				<Grid
					xs={0.5}
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<ArrowLeft
						style={{
							color: "#f29ca4",
							marginLeft: 20,
							height: 60,
							width: 60,
							cursor: "pointer",
						}}
						onClick={() => {
							if (currentPage > 0) {
								setCurrentPage(currentPage - 1);
							}
						}}
					/>
				</Grid>

				<Grid
					xs={8}
					style={{ width: "100%" }}
				>
					<div
						style={{
							width: "100%",
							height: "80px",
							borderRadius: 50,
							backgroundColor: "#f29ca4",
							display: "flex",
							justifyContent: "space-between",
							paddingLeft: 50,
							paddingRight: 50,
							alignContent: "center",
							alignItems: "center",
							color: "#fff",
							fontSize: 30,
							fontWeight: "bold",
						}}
					>
						{chapterList?.[0]?.booktitle}
						<Select
							sx={{
								border: "none",
								"& fieldset": { border: "none" },
								color: "#fff",
								".MuiOutlinedInput-notchedOutline": { border: 0 },
								boxShadow: "none",
							}}
							defaultValue={chapterList?.[0]?.id}
							style={{
								display: "flex",
								justifyContent: "center",
								alignContent: "flex-start",
								alignItems: "flex-start",
							}}
							value={chapter}
							onChange={(val) => setChapter(val.target.value)}
						>
							{chapterList?.map((el) => (
								<MenuItem value={el?.id}>{el?.title}</MenuItem>
							))}
						</Select>
					</div>

					<ReactQuill
						theme='snow'
						/* modules={useMemo(() => modules(), [])} */
						style={{
							height: "60vh",
							borderLeft: "3px solid #ccc",
							borderRight: "3px solid #ccc",
							overflowY: "hidden",
							overflowX: "hidden",
							marginTop: 30,
						}}
						modules={{
							toolbar: false,
						}}
						value={textPage[currentPage]?.text}
						readOnly={true}
						scrollingContainer={".editor"}
					/>
					<div
						style={{
							width: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
						}}
					>
						<h6 style={{ marginLeft: 30 }}>
							Pag. {currentPage + 1}/{textPage.length}
						</h6>

						<div
							style={{
								borderRadius: 16,
								backgroundColor: "#f4e9eb",
								padding: "4px 6px 9px 7px",
								width: 300,
								height: 60,
								marginTop: -30,
								marginRight: 30,
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
								}}
							>
								<span
									style={{
										width: "48px",
										height: "48px",
										margin: "0 0 0 0",
										padding: "10px 10px 10px 10px",
										border: "solid 1px #d99d9d",
										borderRadius: "50%",
										alignContent: "center",
										justifyContent: "center",
										alignItems: "center",
										display: "flex",
										backgroundImage:
											" linear-gradient(to top, #d99d9d, #f7f1d7)",
									}}
								>
									G
								</span>
								<div
									style={{
										borderBottom: "1px solid #000",
										display: "flex",
										flexDirection: "row",
										justifyContent: "center",
										alignContent: "center",
									}}
								>
									<Input
										style={{ width: "50%", border: "none" }}
										type='number'
										min={0}
										step={1}
										value={donation}
										onChange={(e) =>
											e.target.value > 0 || e.target.value === ""
												? setDonation(e.target.value)
												: null
										}
									/>
								</div>
								<Button
									style={{
										borderRadius: 15,
										backgroundColor: "#f29ca4",
										color: "#fff",
										fontWeight: "bold",
									}}
									disabled={!donation || donation <= 0}
									onClick={() => doDonation()}
								>
									Valuta
								</Button>
							</div>
						</div>
					</div>
				</Grid>
				<Grid
					xs={0.5}
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<ArrowRight
						style={{
							marginLeft: 20,
							height: 60,
							width: 60,
							color: "#f29ca4",
							cursor: "pointer",
						}}
						onClick={() => {
							if (currentPage < pages.length - 1) {
								setCurrentPage(currentPage + 1);
							}
						}}
					/>
				</Grid>
			</Grid>
		</div>
	);

	return wallet;
};

export default Read;
